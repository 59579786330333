import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { ClientEntity } from '../model/client-entity';
import * as fromClientEntity from './client-entity.reducer';

export const commonFeatureKey = 'common';

export interface CommonState {
  [fromClientEntity.clientEntityFeatureKey]: fromClientEntity.State;
}

export const reducers: ActionReducerMap<CommonState> = {
  [fromClientEntity.clientEntityFeatureKey]: fromClientEntity.reducer
};


export const metaReducers: MetaReducer<CommonState>[] = !environment.production ? [] : [];

const selectCommonFeatureState = createFeatureSelector<CommonState>(commonFeatureKey);

const selectClientEntityState = createSelector(selectCommonFeatureState, (state: CommonState) => state[fromClientEntity.clientEntityFeatureKey]);
export const selectClientEntity = createSelector(selectClientEntityState, fromClientEntity.getClientEntity);
export const selectClientEntityPayee = createSelector(selectClientEntity, (clientEntity: ClientEntity) => clientEntity.payee);
export const selectClientEntityTransaction = createSelector(selectClientEntity, (clientEntity: ClientEntity) => clientEntity.transaction);
export const selectClientEntityReference = createSelector(selectClientEntity, (clientEntity: ClientEntity) => clientEntity.reference);
export const selectClientEntityTerritory = createSelector(selectClientEntity, (clientEntity: ClientEntity) => clientEntity.territory);
