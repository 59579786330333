import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntitySelectorsFactory} from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IClientEntity, IEntityType } from '../model/client-entity';
import { ISearchView } from '../../application/manager/shared/model/search-view';

@Injectable({providedIn: 'root'})
export class ClientEntityService extends EntityCollectionServiceBase<IClientEntity> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('clientEntity', serviceElementsFactory);
  }

  selectPayeeEntityById(id: number): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.payee.find(v => v.id == id)),
      first());
  }
  selectPayeeEntityByVariable(variable: string): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.payee.find(v => v.variable == variable)),
      first());
  }
  selectTransactionEntityById(id: number): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.transaction.find(v => v.id == id)),
      first());
  }
  selectTransactionEntityByVariable(variable: string): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.transaction.find(v => v.variable == variable)),
      first());
  }
  selectReferenceEntityById(id: number): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.reference.find(v => v.id == id)),
      first());
  }
  selectReferenceEntityByVariable(variable: string): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.reference.find(v => v.variable == variable)),
      first());
  }
  selectTerritoryEntityById(id: number): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.territory.find(v => v.id == id)),
      first());
  }
  selectTerritoryEntityByVariable(variable: string): Observable<IEntityType> {
    return this.entityMap$.pipe(
      map(entities => entities[1]?.territory.find(v => v.variable == variable)),
      first());
  }
}

@Injectable({providedIn: 'root'})
export class SearchViewEntityService extends EntityCollectionServiceBase<ISearchView> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('searchView', serviceElementsFactory);
  }
}
